import SinglesCard from "./singlesCard";
import { Grid } from "@material-ui/core";
import imageHarveyYu from "./harvey-yu.jpeg";
import imageHamzaShah from "./hamza-shah.jpeg";
import imageNazir from "./nazir.jpeg";
import imageKassan from "./kassan.jpeg";
import imageTimon from "./timon.jpeg";
import imageSadiq from "./sadiq.jpeg";

const singles = [
  {
    name: "Harvey Yu",
    description:
      "Harvey graduated in 2021 from the University of Manitoba with a degree in Recreation Management.",
    image: imageHarveyYu,
  },
  {
    name: "Hamza Shah",
    description: "About to buy a cabin with his med school loan.",
    image: imageHamzaShah,
  },
  {
    name: "Nazir Al-Hajjar",
    description: "University of Manitoba graduate with a degree in Civil Engineering.",
    image: imageNazir,
  },
  {
    name: "Kassan Maharajh",
    description: "Almost as good as Skillest One.",
    image: imageKassan,
  },
  {
    name: "Timon Kozelko",
    description: "Cheetos and carrots are the best way into this man's heart.",
    image: imageTimon,
  },
  {
    name: "Sadiq Ahamed",
    description: "Plays AFK Arena.",
    image: imageSadiq,
  },
];

export default function Header() {
  return (
    <Grid key="singles-grid" container spacing={2}>
      {singles.map((single) => {
        return (
          <Grid key={`single-${single.name}`} item xs={12} sm={6} md={4}>
            <SinglesCard {...single} />
          </Grid>
        );
      })}
    </Grid>
  );
}
