import "./App.css";
import { Container, Grid } from "@material-ui/core";
import Header from "./components/Header/header";
import SinglesGrid from "./components/SinglesGrid/singlesGrid";

function App() {
  return (
    <div className="App">
      <Container>
        <Grid key="header" container spacing={2}>
          <Grid key="header" item xs={12}>
            <Header />
          </Grid>
        </Grid>
        <SinglesGrid />
      </Container>
    </div>
  );
}

export default App;
